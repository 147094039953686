<template>
    <div>
        <v-card
            class="pa-2"
            outlined
        >
            <v-form ref="form" class="mt-6 ml-4">
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="params.status"
                            :items="items.status"
                            outlined
                            dense
                            :class="$css_field"
                            clearable
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Status</span>
                            </template>
                        </v-select>
                    </v-col> 
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.codigo"
                            outlined
                            dense
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Codigo</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="params.pasta"
                            outlined
                            dense
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Pasta</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <v-text-field
                            v-model="params.endereco"
                            outlined
                            dense
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Endereço</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                        <v-text-field
                            v-model="params.numero"
                            outlined
                            dense
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Número</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                        <v-text-field
                            v-model="params.apartamento"
                            outlined
                            dense
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Apto</span>
                            </template>
                        </v-text-field>
                    </v-col>    
                </v-row> 
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-text-field
                            v-model="params.owner"
                            outlined
                            dense
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Locador</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <v-text-field
                            v-model="params.tenant"
                            outlined
                            dense
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Locatário</span>
                            </template>
                        </v-text-field>
                    </v-col>
                     <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="params.date_search_model"
                            :items="items.date_search"
                            outlined
                            dense
                            :class="$css_field"
                            clearable
                            item-text="description"
                            item-value="value"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Data pesquisa</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-menu
                            v-model="calendar.date_search_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="params.date_search_from"
                                    append-icon="mdi-calendar-range"
                                    readonly
                                    v-on="on"
                                    :class="$css_field"
                                    clearable
                                >
                                    <template v-slot:label>
                                        <span :class="$css_field">Data de</span>
                                    </template>
                                </v-text-field>
                            </template>

                            <v-date-picker locale="pt-br" v-model="date_search_from" @input="calendar.date_search_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-menu
                            v-model="calendar.date_search_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="params.date_search_to"
                                    append-icon="mdi-calendar-range"
                                    readonly
                                    v-on="on"
                                    :class="$css_field"
                                    clearable
                                >
                                    <template v-slot:label>
                                        <span :class="$css_field">Data até</span>
                                    </template>
                                </v-text-field>
                            </template>

                            <v-date-picker locale="pt-br" v-model="date_search_to" @input="calendar.date_search_to = false"></v-date-picker>
                        </v-menu>
                    </v-col> 
                </v-row>
                <v-row dense>
                    <v-col class="d-flex" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-checkbox
                            v-model="params.payment_date_null"
                            outlined
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Pgto Vencido</span>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col class="d-flex" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-checkbox
                            v-model="params.transfer_owner_date_null"
                            outlined
                            :class="$css_field"
                            @keydown.enter="submit"
                        >
                            <template v-slot:label>
                                <span :class="$css_field">Repasse em aberto</span>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                <v-row dense>    
                    <v-col class="text-right" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-btn
                            color="secondary"
                            @click.prevent="clear" 
                            :class="$css_button"
                            depressed
                        >
                            Limpar
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            color="primary"
                            @click.prevent="submit"
                            :class="$css_button"
                            depressed
                        >
                            Pesquisar
                        </v-btn>
                    </v-col>    
                </v-row>   
            </v-form> 
        </v-card> 
    </div>
</template>

<script>
var moment = require('moment')

import { STATUS, DATE_SEARCH } from '../config_rentals/config_rentals.js'

export default {
    created(){
        this.date_search_from =  ''
        this.date_search_to =  ''
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    }, 

    watch: {
        date_search_from () {
            this.params.date_search_from = moment(String(this.date_search_from)).format('DD/MM/YYYY')
        },

        date_search_to () {
            this.params.date_search_to = moment(String(this.date_search_to)).format('DD/MM/YYYY')
        },
    },

    data() {
        return {
            date_search_from: '',
            date_search_to: '',

            calendar: {
                date_search_from: '',
                date_search_to: ''
            },

            params: {
                id: '',
                date_search_from: '',
                date_search_to: ''
            },
            
            items: {
                status: STATUS,
                date_search: DATE_SEARCH
            },
        }
    },

    methods: {

        clear() {
            this.$refs.form.reset()
        },

        submit(){
            if(!this.me.id){
                this.$store.dispatch('logout')
            }
            this.params.id = ''
            if(this.params.payment_date_null && this.params.payment_date_null == true){
                this.params.payment_date_null = 'true'
            }
            if(this.params.transfer_owner_date_null && this.params.transfer_owner_date_null == true){
                this.params.transfer_owner_date_null = 'true'
            }
            this.$emit('callLoadItems',this.params)
        },

    }
}
</script>