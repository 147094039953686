import axios from "axios"
import { URL_BASE } from '../../../config/config'

const RESOURCE = 'rentals/rentals'


export default {
    state: {
        items: {
            data: []
        },
    },

    mutations: {
        LOAD_RENTALS (state, data) {
            state.items = data
        },
    },

    actions: {
        loadRentals(context, params) {
            context.commit('PRELOADER', true)
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then( response =>
                        {
                            context.commit('LOAD_RENTALS', response.data)
                        }
                )
                //.catch( errors => console.log(errors))
                .catch((error) => {
                    if(error.response && error.response.status === 401) {
                        window.location.href = "login";
                    }

                })
                .finally(() => context.commit('PRELOADER', false))
        },

        loadPromisseRentals(context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`, {params})
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        storeRental (context, params) {
            context.commit('PRELOADER', true)
            return new Promise((resolve) => {
                axios.post(`${URL_BASE}${RESOURCE}`, params)
                    .then(response => resolve(response.data))
                    .catch(error => console.log(error))
                    .finally(() => context.commit('PRELOADER', false))
            })
        },
        
        updateRental(context, params){
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}${RESOURCE}/${params.id}`, params)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        loadRental(context, id) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        },

        destroyRental(context, id){
            
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
                    //.finally(() => context.commit('PRELOADER', false))    
            })
            
        },

        rentalExport(context, params) {         
            return new Promise((reject) => {
                //console.log(`${URL_BASE}rentals/rental_export`)
                axios.get(`${URL_BASE}rentals/rental_export`, {
                    responseType: "blob",
                    params: params 
                  })
                    .then(response => {
                        const url = URL.createObjectURL(
                            new Blob([response.data], {
                                type: "application/vnd.ms-excel"
                            })
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "contratos_locacao.xls");
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(function(error) {
                        if (error.response && error.response.status === 401) {
                          window.location.href = "login";
                        }
                        reject(error)
                    })
                    .finally(() => context.commit('PRELOADER', false))    
            }) 
        },

    },

    getters: {

    }

}