<template>
    <div>
        <v-row class="mt-1">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped caption"
                    :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:[`item.details`]="{ item }">
                        <div class="mt-2 mb-2">
                            <div :class="$css_show_title">
                                
                                
                                <v-chip
                                    dark
                                    :color="item.codigo | chipDefaultColor"
                                    label
                                    small
                                    :class="$css_button + ' mr-2'"
                                >
                                CODIGO  {{ item.codigo }}
                                </v-chip>

                                <v-chip
                                    dark
                                    :color="item.status | statusRentalColor"
                                    label
                                    small
                                    :class="$css_button  + ' mr-2'"
                                >
                                    {{ item.status.toUpperCase() }}
                                </v-chip>

                                <span v-if="item.tipo_imovel">({{ item.tipo_imovel }})</span>
                                {{ item.endereco }}

                                <span v-if="item.numero">, {{ item.numero }}</span>
                                <span v-if="item.apartamento"> APTO:  {{ item.apartamento }}</span>
                                <span v-if="item.complemento"> Compl:  {{ item.complemento }}</span>
                            </div>
                            <!--div :class="$css_report_list">
                                <span v-if="item.bairro"> {{ item.bairro }}</span>
                                <span v-if="item.cidade"> - {{ item.cidade }}</span>
                                <span v-if="item.estado"> - {{ item.estado }}</span>
                            </div-->

                            <div :class="$css_report_list + ' mt-2 '">
                                <div>
                                    <span class="font-weight-medium">Proprietários:</span> 
                                    <span v-if="item.json_rental_owners && item.json_rental_owners.length > 0">
                                        <span v-for="(cl, index) in item.json_rental_owners" :key="cl.id">
                                            {{ cl.nome }}
                                            <span v-if="(index + 1) < item.json_rental_owners.length">, </span>
                                        </span>
                                    </span>
                                </div>

                                <div>
                                    <span class="font-weight-medium">Locatários:</span> 
                                    <span v-if="item.json_rental_tenants && item.json_rental_tenants.length > 0">
                                        <span v-for="(cl, index) in item.json_rental_tenants" :key="cl.id">
                                            {{ cl.nome }}
                                            <span v-if="(index + 1) < item.json_rental_tenants.length">, </span>
                                        </span>
                                    </span>

                                </div>
                                
                            </div>
                        </div>
                    </template>    

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>

                             <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template> 
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>

export default {

    components: {
    },

    props: {
        object: {},

        filters: {},
    }, 

    watch: {
        options: {
            handler() {
                if(this.options.page >= 1){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    data () {
        return {
            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'CONTRATO', value: 'details' , sortable: false},
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    methods: {
        loadItems(){
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$emit('callEditItem', id)
        },

        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.$store.dispatch('destroyRental', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Delete')
                            this.$emit('callLoadItems')
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },
    },
}
</script>
