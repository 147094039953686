<template>
    <div>
        <v-card
            elevation="0"
            outlined
            class="pa-3 rounded-0"
        >
            <v-card-title class="pa-0 mt-0 mb-2">Somente mensalidades sem pagamentos e ou repasse registrados poderão ser excluídos</v-card-title>
            <v-form ref="form" class="p-2">
                <v-row dense>                    
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="item.due_date_from"
                            :items="items.rental_monthly_fees"
                            :rules="[rules.required]"
                            outlined
                            :class="$css_field_large"
                            item-text="due_date"
                            item-value="due_date"
                        
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">A partir de</span>
                            </template>

                            <template v-slot:item="{ item }">
                                <span :class="$css_field_large">{{ formatDate(item.due_date) }}</span>
                            </template>

                            <template v-slot:selection="{ item }">
                                <span :class="$css_field_large">{{ item ? formatDate(item.due_date) : '' }}</span>
                            </template>
                        </v-select>        
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-select
                            v-model="item.due_date_to"
                            :items="items.rental_monthly_fees"
                            :rules="[rules.required]"
                            outlined
                            :class="$css_field_large"
                            item-text="due_date"
                            item-value="due_date"
                        
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">Até</span>
                            </template>

                            <template v-slot:item="{ item }">
                                <span :class="$css_field_large">{{ formatDate(item.due_date) }}</span>
                            </template>

                            <template v-slot:selection="{ item }">
                                <span :class="$css_field_large">{{ item ? formatDate(item.due_date) : '' }}</span>
                            </template>
                        </v-select>        
                    </v-col>
                </v-row>    
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                        <v-btn
                            x-large
                            depressed
                            color="secondary"
                            @click.prevent="validate()"
                            :class="$css_button_large"
                        >
                            excluir
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>            
    </div>
</template>

<script>
var moment = require('moment')

import { CHARGES, DISCOUNTS, TRANSFER_TO, TYPES, ADMS } from '../config_rentals/config_rentals.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

     watch: {
        object () {   
            this.setObject()
        },
    },

    data() {
        return {
            type: '',

            item: {
                rental_id: '',    
                due_date_from: '',
                due_date_to: '',
            },

            items: {
                types: TYPES,
                charges: CHARGES,
                discounts: DISCOUNTS,
                adm: ADMS,
                transfer_to: TRANSFER_TO,
                rental_monthly_fees: [],
                description: []
            },

            rules: {
                required: value => !!value || 'Obrigatorio',
                min_value:  v => (v && v.length >= 6) || 'O valor precisa ser maior que R$ 100',
            },

            params: {
                rental_id: '',
                paginate: 'false',
                date_search_null: ['payment_date','transfer_owner_date'],
                date_search_null_concat: 'and'
            }
        }
    },

    methods: {
        setItemsDescription(){
            this.items.description = []
            if(this.item.type == 'discount') {
                this.items.description = this.items.discounts
            }
            if(this.item.type == 'charge') { 
                this.items.description = this.items.charges
            }

            if(this.item.type == 'adm') { 
                this.items.description = this.items.adm 
            }       
        },

        setObject(){
            this.loadPromisseRentalMonthlyFees()  
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.item.rental_id = this.object.id
                this.massDestroyMonthlyFee(this.item)
            }
        },

        clear() {
            this.$refs.form.reset()
        },

        massDestroyMonthlyFee(item){
            this.$store.dispatch('massDestroyMonthlyFee', item)
                        .then(response => {
                            this.$snotify.success('Mensalidade(s) excluídas com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        }, 

        loadPromisseRentalMonthlyFees(){
            this.params.rental_id = this.object.id
            this.$store.dispatch('loadPromisseRentalMonthlyFees', this.params)
                        .then((response) => {
                            this.items.rental_monthly_fees = response.data
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
            
        },

    }
}
</script>