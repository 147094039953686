<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                   <v-menu
                        v-model="calendar.primeiro_vencimento"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="item.primeiro_vencimento"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Primeiro Vencimento</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="primeiro_vencimento" @input="calendar.primeiro_vencimento = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.n_monthly"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">N. de mens. a gerar</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.aluguel"
                        outlined
                        dense
                        :rules="[rules.required, rules.min_value]"
                        prefix="R$"
                        :class="$css_field"
                        @blur="calcAdm()"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Valor aluguel</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.taxa_adm"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        readonly
                        filled
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Taxa adm %</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_taxa_adm"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Valor taxa adm R$</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col v-if="object.status == 'Pendente'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.comissao"
                        type="number"
                        outlined
                        dense
                        readonly
                        filled
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Comissão %</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="object.status == 'Pendente'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.valor_comissao"
                        outlined
                        dense
                        prefix="R$"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Valor comissão</span>
                        </template>
                    </v-currency-field>
                </v-col>
            </v-row>    

            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>   
    </div>
</template>
<script>
export default {
    
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            this.setObject()
        },

        primeiro_vencimento () {
            this.item.primeiro_vencimento = this.formatDate(this.primeiro_vencimento)
        },

    },

    data () {
        return {
            apply_comission: false,

            item: {
                valor_taxa_adm: 0,
                valor_comissao: 0,
                n_monthly: 0,
            },
            
            rules: {
                required: value => !!value || 'Obrigatorio',
                min_value:  v => (v && v.length >= 6) || 'O valor precisa ser maior que R$ 100',
            },

            primeiro_vencimento: '',
            calendar: {
                primeiro_vencimento: ''
            }
        }
    },

    methods: {
        calcAdm(){
            const taxa_adm = (this.item.aluguel * this.item.taxa_adm) / 100;
            this.item.valor_taxa_adm = taxa_adm.toFixed(2); // Limita a 2 casas decimais
            
            const comissao = (this.item.aluguel * this.item.comissao) / 100;
            this.item.valor_comissao = comissao.toFixed(2); // Limita a 2 casas decimais

        },

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item.rental_id = this.object.id
                this.item.primeiro_vencimento = this.object.inicio_contrato
                this.item.n_monthly = this.object.reajuste
                this.item.comissao = this.object.comissao
                this.item.taxa_adm = this.object.taxa_adm
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.store(this.item)
            }
        },

        store(item){
            this.$store.dispatch('storeRentalMonthlyFee', item)
                        .then(response => {
                            this.item = response.data 
                            this.$emit('callLoadItem',this.object.id)
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
    },
}
</script>
