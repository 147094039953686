<template>
    <div>
        {{ me }}
    </div>
</template>

<script>
export default {
    components: {
    },

    computed: {
        me () {
          return this.$store.state.auth.me
        },
    }, 

    data (){
        return {
            url: process.env.VUE_APP_ROOT_API,
        }    
    },

    methods: {
        
    }
}
</script>
