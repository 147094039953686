<template>
    <div>
        <v-card
            elevation="0"
            outlined
            class="pa-3 rounded-0"
        >
        <v-card-title class="pa-0 mt-0 mb-2">{{ object.title }}</v-card-title>
        <v-form ref="form" class="p-2">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.description"
                        :items="items.options"
                        outlined
                        :class="$css_field_large"
                    >
                        <template v-slot:label>
                            <span :class="$css_field_large">Descrição</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-currency-field
                        v-model="item.value"
                        outlined
                        prefix="R$"
                        :class="$css_field_large"
                    >
                        <template v-slot:label>
                            <span :class="$css_field_large">Valor</span>
                        </template>
                    </v-currency-field>
                </v-col>
                <v-col cols="12" v-if="object.type == 'charge'"  xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.transfer_to"
                        :items="items.transfer_to"
                        outlined
                        :class="$css_field_large"
                    >
                        <template v-slot:label>
                            <span :class="$css_field_large">Repasse para:</span>
                        </template>
                    </v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.obs"
                        outlined
                        :class="$css_field_large"
                    >
                        <template v-slot:label>
                            <span :class="$css_field_large">Obs (opcional)</span>
                        </template>
                    </v-text-field>
                </v-col>
               
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" class="text-right">
                    <v-btn
                        x-large
                        depressed
                        block
                        color="secondary"
                        @click.prevent="validate()"
                        :class="$css_button_large"
                    >
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <v-data-table
            :headers="head"
            :items="object.items"
            disable-pagination
            hide-default-footer
            class="elevation-0 table-striped caption"
        >
            <template v-slot:[`item.description`]="{ item }">
                <span :class="$css_report_list">
                    {{ item.description }}
                    <span v-if="item.obs"> ({{ item.obs }})</span>
                </span>
            </template> 

            <template v-slot:[`item.value`]="{ item }">
                <span :class="$css_report_list">{{ item.value | toCurrency }}</span>
            </template> 

            <template v-slot:[`item.transfer_to`]="{ item }">
                <span :class="$css_report_list">{{ item.transfer_to }}</span>
            </template>  

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>
            </template>                  
        </v-data-table>

        </v-card>
    </div>
</template>

<script>
import { TRANSFER_TO } from '../rentals/config_rentals/config_rentals.js'

export default {
    name: 'RentalMonthlyFeeValueComponent',

    created () {
        this.setObject()
    },

    data () {
        return {
            item: {
                rental_monthly_fee_id: '',
                type: '',
                description: '',
                value: '',
                transfer_to: ''
            },

            items:{
                transfer_to: TRANSFER_TO,
                options: []
            },

            rules: {
                required: value => !!value || 'Required.',
            },

            head:[],

            headers: [
                { text: 'DESCRIÇÃO', value: 'description' , sortable: true},
                { text: 'VALOR R$', value: 'value' , sortable: true},
                { text: 'AÇÕES', value: 'actions', sortable: true },
            ],

            headers2: [
                { text: 'DESCRIÇÃO', value: 'description' , sortable: true},
                { text: 'VALOR R$', value: 'value' , sortable: true},
                { text: 'REPASSE PARA', value: 'transfer_to' , sortable: true},
                { text: 'AÇÕES', value: 'actions', sortable: true },
            ],
        }
    },

    props: {
        object: {
            require: true,
        },
    },

    watch: {
        object () {   
            this.setObject()
        },
    },    
    
    methods: {
        setObject(){
            this.item.rental_monthly_fee_id = this.object.rental_monthly_fee_id
            this.item.type = this.object.type
            this.items.options = this.object.options
            this.head = this.headers;
            if(this.item.type == 'charge'){
                this.head = this.headers2
            }
        },
        
        validate () {
            if (this.$refs.form.validate()) {
                this.store()
            }
        },

        store(){      
            this.item.rental_monthly_fee_id = this.object.rental_monthly_fee_id   
            this.$store.dispatch('storeRentalMonthlyFeeValue', this.item)
                        .then(response => {
                            this.$emit('callLoadRentalMonthlyFee', this.item.rental_monthly_fee_id)
                            this.clear()
                            this.setObject()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        clear() {
            this.$refs.form.reset()
        },

        destroy(item){
            this.$store.dispatch('destroyRentalMonthlyFeeValue', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Delete')
                            this.$emit('callLoadRentalMonthlyFee', this.item.rental_monthly_fee_id)
                            this.setObject()
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },

    }
}
</script>