<template>
    <div>
        <v-form ref="form" class="p-2">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.nome"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12"  xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                        :rules="[rules.required]"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>

                </v-col>
                <v-col cols="12"  xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail (opcional)</span>
                        </template>
                    </v-text-field>
                </v-col>

                <!--v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.description"
                        label="Obs (opcional)"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs (opcional)</span>
                        </template>
                    </v-text-field>
                </v-col-->
               
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn
                        color="primary"
                        @click.prevent="validate()"
                        :class="$css_button"
                    >
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
export default {
    name: 'FormClientComponent',

    created () {
        this.setObject()
    },

    data () {
        return {
            item: {},
            rules: {
                required: value => !!value || 'Obrigatório.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },
        }
    },

    props: {
        object: {
            require: true,
        },
    },
    
    methods: {
        setObject(){
            this.item = this.object
        },
        
        validate () {
            if (this.$refs.form.validate()) {
                this.store()
            }
        },

        store(){
            this.item.rental_id = this.object.rental_id
            const action = 'storeRentalClient'
            this.$store.dispatch(action, this.item)
                        .then(response => {
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callNewClientDone', this.object.rental_id)
                            this.clear()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
        },

        clear() {
            this.$refs.form.reset()
        },


    }
}
</script>