<template>
    <div>
        <form-search-client-component v-if="!newclient" :object="this.object" @callLoadItem="loadItem" @callNewClient="newClient"></form-search-client-component>
        <form-client-component v-if="newclient" :object="item" @callNewClientDone="newClientDone"></form-client-component>
        <!-- List  --> 
        <v-row v-if="object.items" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="object.items"
                        :items-per-page="10"
                        sort-by="id"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',   
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <span :class="$css_report_list">{{ item.nome }}</span>
                        </template>

                        <template v-slot:[`item.telefone`]="{ item }">
                            <phone-component v-if="item.telefone" :object="item.telefone"></phone-component> 
                            <phone-component v-if="item.telefone2" :object="item.telefone2"></phone-component>
                        </template>

                        <template v-slot:[`item.email`]="{ item }">
                            <span :class="$css_report_list">{{ item.email }}</span>
                        </template>

                        <template v-slot:[`item.obs`]="{ item }">
                            <span :class="$css_report_list">{{ item.obs }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="edit(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click.prevent="destroy(item)" class="ml-8">
                                            <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <!-- Dialog -->
        <v-dialog 
            v-model="dialog.client" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    
            <v-card class="d-print-none" >
                <v-card-title class="text-h5">
                    <span v-if="client.codigo">Código {{client.codigo}} - {{client.nome}}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialogClient">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <client-component :object="client" @callLoadItem="loadItem"></client-component>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->
    </div>
</template>

<script>
import ClientComponent from '../clients/ClientComponent.vue'
import AlertComponent from '../alert/AlertComponent.vue'
import FormSearchClientComponent from './FormSearchClientComponent.vue'
import FormClientComponent from './FormClientComponent.vue'
import PhoneComponent from '@/components/phone/PhoneComponent.vue'


export default {
    components: {
        ClientComponent,
        AlertComponent,
        FormSearchClientComponent,
        FormClientComponent,
        PhoneComponent
    },

    props: {
        object: {
            require: true,
        },
    },

    data () {
        return {
            newclient: false,

            dialog: {
                client: false
            },

            search_type: 'Telefone',

            item: {
                rental_id: '',    
                nome: '',
                telefone: '',
                email: '',
                description: ''            
            },

            items: {
                search_types: ['Telefone','E-mail','Nome']
            },

            rules: {
                required: value => !!value || 'Obrigatório.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            headers: [
                { text: 'NOME', align: 'start', value: 'nome', },
                { text: 'TELEFONE', value: 'telefone' },
                { text: 'EMAIL', value: 'email' },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],


            client: {}
        }
    },
    
    methods: {

       destroy(item){
            this.$store.dispatch('destroyRentalClient', item.id)
                        .then(response => {
                            this.$emit('callLoadItem',this.object.rental_id)
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                            this.errors = error.response.data.errors
                        })
                        .finally(
                            //this.$snotify.remove()
                            this.$snotify.success('Registro excluído com sucesso!')
                        )
        },

        edit (item) {    
            this.$store.dispatch('loadClient', item.client_id) 
                        .then(response => {
                            this.client = response.data
                            this.client.rental_id = item.rental_id
                            this.client.profile = this.object.profile
                            this.client.description = item.description
                            this.dialog.client = true
                        })
                                   
        },

        openCloseDialogClient(){
            this.dialog.client = !this.dialog.client
        },

        loadItem(){
            this.$emit('callLoadItem', this.object.rental_id)
        },

        setNewClient(){
            this.newclient = !this.newclient 
        },

        newClient(params){
            this.item.nome = params.nome
            this.item.email = params.email
            this.item.telefone = params.telefone
            this.item.rental_id = this.object.rental_id
            this.item.profile = this.object.profile
          
            this.setNewClient()
        },

        newClientDone(rental_id){
            this.item.nome = ''
            this.item.email = ''
            this.item.telefone = ''
            this.setNewClient()
            this.loadItem(rental_id)
        }
    }
}        
</script>