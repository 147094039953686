import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginComponent from '../components/login/LoginComponent'
import CloseRequestPasswordComponent from '../components/closerequestpassword/CloseRequestPasswordComponent'
import AdminComponent from '../components/AdminComponent'
import DashboardComponent from '../components/dashboard/DashBoardComponent'
import UserComponent from '../components/users/UserComponent'
import RentalComponent from '../components/rentals/RentalComponent'

import store from '../store/index.js'

import { NAME_TOKEN } from '../config/config'

Vue.use(VueRouter)

const routes = [
    { path: '/login', component: LoginComponent, name: 'login' },
    { path: '/closerequestpassword', component: CloseRequestPasswordComponent, name: 'closerequestpassword' },
    
    {
        path: '/admin',
        component: AdminComponent,
        meta: {auth: true},
        children: [
            { path: '/dashboard', component: DashboardComponent, name: 'admin.dashboard' },
            { path: '/rentals', component: RentalComponent, name: 'admin.rentals.index' },
            { path: '/users', component: UserComponent, name: 'admin.users.index'}
        ]
    },

    { path: '/', component: LoginComponent, name: 'root'}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
 
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth) && !store.state.auth.authenticated) {
        const token = localStorage.getItem(NAME_TOKEN)
        if(!token){
            return router.push({name: 'login'})  
        }else{
            store.dispatch('checkLogin')
        } 
        //store.commit('CHANGE_URL_BACK', to.name) // apos o refresh ele volta para a pagina atual
        //return router.push({name: 'login'})
    }
    next()
})



export default router
