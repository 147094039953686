<template>
    <div>
        <list-component></list-component>
    </div>
</template>

<script>
import ListComponent from './partials/ListComponent.vue'

export default {
    name: 'RentalComponent',

    components: {
        ListComponent
    },

    data () {
        return {
          
        }
    },
}
</script>
