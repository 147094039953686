import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask'
import Snotify from 'vue-snotify'; 
import 'vue-snotify/styles/material.css'; // or dark.css or simple.css
import methods from './methods/methods.js'
import browserDetect from "vue-browser-detect-plugin";
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField
import VueSocialSharing from 'vue-social-sharing'
import JsonExcel from "vue-json-excel"
import { URL_UPLOAD, URL_IMAGE, CSS } from './config/config.js'
import FileSaver from 'file-saver'

Vue.config.productionTip = false
Vue.use(VueAxios, axios) 
Vue.use(Snotify, {toast: {showProgressBar: false}})
Vue.use(VueMask)
Vue.use(VueTheMask)
Vue.use(browserDetect)
Vue.use(require('vue-moment'))
Vue.use(VueSocialSharing);

Vue.component("downloadExcel", JsonExcel);
Vue.component('v-text-field', VTextField)

Vue.use(VCurrencyField, {
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0
})

var moment = require('moment')

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    return value.toUpperCase();
})


Vue.filter('formatDate', function (value) {
    if (!value) return ''
    if(value != '' && value != null){
        return moment(String(value)).format( 'DD/MM/YYYY')
    }else{
        return ''
    }  
})

Vue.filter('urlUpload', function (value) {
    if (!value) return ''
    if(value.urlremoto != ''){
        return value.urlremoto
    }
})

Vue.filter('urlYoutube', function (value) {
    if (!value) return ''
    return 'https://www.youtube.com/watch?v=' + value
})

Vue.filter('urlImage', function (value) {
    if (!value) return ''
    return URL_IMAGE + value
})

Vue.filter('statusTextPendenteAtivoInativo', function (value) {
    if (!value) return ''

    if(value == 'Pendente')             return 'red--text'
    else if(value == 'Ativo')           return 'green--text'
    else if(value == 'Inativo')         return 'orange--text'
    else return ''
})


Vue.filter('statusPAI', function (value) {
    if (!value) return ''

    if(value == 'Pendente')             return 'red lighten-4'
    else if(value == 'Ativo')           return 'green lighten-4'
    else if(value == 'Inativo')         return 'orange lighten-4'
    else if(value == 'Suspenso')         return 'brown lighten-4'
    else return ''
})

Vue.filter('statusTenant', function (value) {
    if (!value) return ''

    if(value == 'Pendente')      return 'orange lighten-2'
    else if(value == 'Ativo')    return 'green lighten-2'
    else if(value == 'Suspenso') return 'red lighten-2'
    else return ''
})

Vue.filter('statusMens', function (value) {
    if (!value) return ''

    if(value == 'Mens. vencida')    return 'red lighten-2'
    else if(value == 'NF pendente') return 'orange lighten-2'
    else return ''
})

Vue.filter('statusProposal', function (value) {
    if (!value) return ''

    if(value == 'Nova em andamento')    return 'purple lighten-4'
    else if(value == 'Nova')            return 'blue lighten-4'
    else if(value == 'Pendente')        return 'pendente lighten-4'
    else if(value == 'Em análise')      return 'orange lighten-4'
    else if(value == 'Concluído')       return 'green lighten-4'
    else return ''
})

Vue.filter('statusFac', function (value, type = null) {
    if(!type){
        type = ' lighten-4'
    }
    
    if (!value) return ''

    if(value == 'Nova') return 'green' + type
    else if(value == 'Em atendimento')  return 'blue' + type
    else if(value == 'Concluída')   return 'brown' + type
    else if(value == 'Desatualizada')   return 'red' + type
    else if(value == 'Cliente em Espera')   return 'orange' + type
    else if(value == 'Troca de Atendimento')   return 'deep-purple' + type
    else if(value == 'Reatendimento')   return 'indigo' + type
    else return ''
})

Vue.filter('statusProperty', function (value, type = null) {
    if(!type){
        type = ' lighten-4'
    }

    if (!value) return ''

    if(value == 'Pendente')             return 'red' + type
    else if(value == 'Ativo')           return 'green' + type
    else if(value == 'Suspenso')         return 'purple' + type
    else if(value == 'Reservado')         return 'orange' + type
    else if(value == 'Alugado pela Imobiliária')         return 'blue-grey' + type
    else if(value == 'Vendido pela Imobiliária')         return 'blue-grey' + type
    else if(value == 'Alugado por Terceiros')         return 'blue-grey' + type
    else if(value == 'Vendido por Terceiros')         return 'blue-grey' + type
    else if(value == 'Desatualizado')         return 'brown' + type
    else if(value == 'Cancelado')         return 'light-blue' + type
    else return ''
})

Vue.filter('userProfileColor', function (value) {
    if(!value) return ''
    if(value == 'Gerente') return 'blue lighten-4'
    else if(value == 'Master') return 'orange lighten-4'
    else if(value == 'Corretor') return 'green lighten-4'
    else return ''
})

Vue.filter('statusRentalColor', function (value) {
    if(!value) return ''
    if(value == 'Pendente') return 'orange darken-4'
    else if(value == 'Em vigência') return 'secondary'
    else if(value == 'Concluído') return 'accent'
    else if(value == 'Cancelado') return 'indigo darken-4'
    else if(value == 'Jurídico') return 'error'
    else return 'indigo darken-4'
})

Vue.filter('chipDefaultColor', function (value) {
    return '#37474F'
})

Vue.filter('alertColor', function (value) {
    if(!value) return ''
    if(value == 'info') return 'blue lighten-1'
    else if(value == 'success') return 'green lighten-1'
    else if(value == 'error') return 'red lighten-1'
    else if(value == 'warning') return 'orange lighten-1'
    else return ''
})

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    return formatter.format(value);
});

Vue.filter('toCommaSpace', function (value) {
    if(!value) return ''
    return value.replace(',',', ');
});

Vue.filter('convertArrayToString', function (value) {
    if(!value) return ''
    const str = value
    var result = ''
    str.forEach(model => {
       if(result != ''){
         result += ', '
       }
       result += model
    })
    return result
});


// CSS
Vue.prototype.$css_back_ground_color = 'background: #f1f4f6;'
Vue.prototype.$css_report_list = CSS.report_list
Vue.prototype.$css_field = CSS.field
Vue.prototype.$css_field_large = CSS.field_large
Vue.prototype.$css_button = CSS.button
Vue.prototype.$css_button_large = CSS.button_large
Vue.prototype.$css_show_title = CSS.show_title
Vue.prototype.$css_show_description = CSS.show_description
Vue.prototype.$css_title_module = CSS.show_title_module
Vue.prototype.$css_main_value = CSS.main_value
Vue.prototype.$css_sub_value = CSS.sub_value


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.mixin({
    methods: methods
})
