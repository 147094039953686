<template>
    <div>
        <v-form ref="form">
            <v-row dense class="mt-6">
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.due_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                outlined
                                v-model="data_fee.due_date"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field_large"
                                :rules="[rules.required]"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field_large">Vencimento</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="due_date" @input="calendar.due_date = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.payment_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                outlined
                                v-model="data_fee.payment_date"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field_large"
                                clearable
                            >
                                <template v-slot:label>
                                    <span :class="$css_field_large">DT Pagamento</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="payment_date" @input="calendar.payment_date = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.transfer_owner_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                outlined
                                v-model="data_fee.transfer_owner_date"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field_large"
                                clearable
                            >
                                <template v-slot:label>
                                    <span :class="$css_field_large">DT Repasse</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="transfer_owner_date" @input="calendar.transfer_owner_date = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="data_fee.update_value"
                        :items="items.update_value"
                        :rules="[rules.required]"
                        outlined
                        :class="$css_field_large"
                    >
                        <template v-slot:label>
                            <span :class="$css_field_large">Alerta de atualização</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn
                        depressed
                        x-large
                        block
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button_large"
                    >
                     Gravar
                    </v-btn>
                </v-col>                        
            </v-row>
        </v-form>
            
        <!-- charges -->
        <div class="mb-4">
            <rental-monthly-fee-value-component :object="charges" @callLoadRentalMonthlyFee="loadRentalMonthlyFee"></rental-monthly-fee-value-component>  
        </div>
        
        <!-- discounts -->
        <div class="mb-4">
            <rental-monthly-fee-value-component :object="discounts" @callLoadRentalMonthlyFee="loadRentalMonthlyFee"></rental-monthly-fee-value-component>    
        </div>    

         <!-- adms -->
        <div class="mb-4">
            <rental-monthly-fee-value-component :object="adms" @callLoadRentalMonthlyFee="loadRentalMonthlyFee"></rental-monthly-fee-value-component>    
        </div>    
    </div>
</template>

<script>
import { CHARGES, DISCOUNTS } from '../config_rentals/config_rentals.js'
import RentalMonthlyFeeValueComponent from '@/components/rental_monthly_fee_values/RentalMonthlyFeeValueComponent.vue'

export default {
    components: {
        RentalMonthlyFeeValueComponent,
    },

    created () {
        this.setObject()
    },

    props: {
        object: {},
    }, 

    watch: {
        object () {   
            this.setObject()
        },

        due_date () {
            this.data_fee.due_date = this.formatDate(this.due_date)
        },

        payment_date () {
            this.data_fee.payment_date = this.formatDate(this.payment_date)
        },

        transfer_owner_date () {
            this.data_fee.transfer_owner_date = this.formatDate(this.transfer_owner_date)
        },

        deep: true
    },

    data () {
        return {    
            due_date: '',
            payment_date: '',
            transfer_owner_date: '',

            calendar: {
                due_date: '',
                payment_date: '',
                transfer_owner_date: '',
            },

            data_fee: {
                due_date: '',
                payment_date: '',
                transfer_owner_date: '',
            },

            items: {
                update_value: ['Sim','Não']
            },

            rules: {
                required: value => !!value || 'Obrigatorio',
            },

            charges: {
                title: 'Aluguel e encargos',
                type: 'charge',
                options: CHARGES,
                items: [],
                rental_monthly_fee_id: '',
            },

            discounts: {
                title: 'Descontos',
                type: 'discount',
                options: DISCOUNTS,
                items: [],
                rental_monthly_fee_id: '',
            },

            adms: {
                title: 'Comissão e Taxa Adm',
                type: 'adm',
                options: ['Comissão','Taxa adm'],
                items: [],
                rental_monthly_fee_id: '',
            },  
               
        }    
    },

    methods: {
        setObject(){
            this.data_fee = this.object
            this.charges.rental_monthly_fee_id = this.object.id
            this.discounts.rental_monthly_fee_id = this.object.id
            this.adms.rental_monthly_fee_id = this.object.id
            this.setRentFeeValue()    
        },

        validate(){
            if (this.$refs.form.validate()) {
                this.update(this.data_fee)
            }
        },

        update(item){
            this.$store.dispatch('updateRentalMonthlyFee', item)
                        .then((response) => {
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
            
        },

        loadPromisseRentalMonthlyFees(id){
            this.$emit('callLoadPromisseRentalMonthlyFees', id)
        },

        loadRentalMonthlyFee(id){
            this.$store.dispatch('loadRentalMonthlyFee', id)
                        .then(response => {
                            this.data_fee = response.data
                            this.data_fee.due_date = this.formatDate(this.data_fee.due_date)
                            this.data_fee.payment_date = this.formatDate(this.data_fee.payment_date)
                            this.data_fee.transfer_owner_date = this.formatDate(this.data_fee.transfer_owner_date)
                            
                            this.charges.rental_monthly_fee_id = this.data_fee.id
                            this.discounts.rental_monthly_fee_id = this.data_fee.id
                            this.adms.rental_monthly_fee_id = this.data_fee.id
                            this.setRentFeeValue() 
                        })
                        .catch(error => {
                            console.log(error)
                        })
        },

        setRentFeeValue(){
            this.charges.items = []
            this.discounts.items = []
            this.adms.items = []

            if(this.data_fee.json_rental_monthly_fee_values && this.data_fee.json_rental_monthly_fee_values.length > 0){ 
                const charges = this.data_fee.json_rental_monthly_fee_values.filter(model => model.type == 'charge')
                if(charges && charges.length > 0){
                    this.charges.items = charges
                }
                
                const discounts = this.data_fee.json_rental_monthly_fee_values.filter(model => model.type == 'discount')
                if(discounts && discounts.length > 0){
                    this.discounts.items = discounts
                }
                
                const adms = this.data_fee.json_rental_monthly_fee_values.filter(model => model.type == 'adm')
                if(adms && adms.length > 0){
                    this.adms.items = adms
                }
            }
        }

    },
}
</script>