<template>
    <div>
        <v-card
            elevation="0"
            outlined
            class="pa-3 rounded-0"
        >
            <v-card-title class="pa-0 mt-0 mb-2">Adicionar mensalidades</v-card-title>

            <v-form ref="form" class="p-2">
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="item.aluguel"
                            outlined
                            prefix="R$"
                            :class="$css_field_large"
                            :rules="[rules.required]"
                            @blur="calcAdm()"
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">Aluguel</span>
                            </template>
                        </v-currency-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="item.taxa_adm"
                            :rules="[rules.required]"
                            type="number"
                            outlined
                            readonly
                            filled
                            :class="$css_field_large"
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">Taxa adm %</span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-currency-field
                            v-model="item.valor_taxa_adm"
                            outlined
                            prefix="R$"
                            :class="$css_field_large"
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">Valor taxa adm R$</span>
                            </template>
                        </v-currency-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="item.n_monthly"
                            :rules="[rules.required]"
                            type="number"
                            outlined
                            :class="$css_field_large"
                        >
                            <template v-slot:label>
                                <span :class="$css_field_large">N. de mens. a gerar</span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>    
                <v-row dense>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center">
                        <v-btn
                            x-large
                            depressed
                            color="secondary"
                            @click.prevent="validate()"
                            :class="$css_button_large"
                        >
                            Adicionar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>            
    </div>
</template>

<script>
import { CHARGES, TRANSFER_TO } from '../config_rentals/config_rentals.js'

export default {
    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

     watch: {
        object () {   
            this.setObject()
        },
    },

    data() {
        return {
            item: {
                rental_id: '',    
                aluguel: 0,
                taxa_adm: 0,
                valor_taxa_adm: 0
            },

            items: {
                charges: CHARGES,
                transfer_to: TRANSFER_TO
            },

            rules: {
                required: value => !!value || 'Obrigatorio',
                min_value:  v => (v && v.length >= 6) || 'O valor precisa ser maior que R$ 100',
            },
        }
    },

    methods: {
        calcAdm(){
            const taxa_adm = (this.item.aluguel * this.item.taxa_adm) / 100;
            this.item.valor_taxa_adm = taxa_adm.toFixed(2); // Limita a 2 casas decimais
        },

        setObject(){
            this.item.taxa_adm = this.object.taxa_adm
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.item.rental_id = this.object.id
                this.store(this.item)
            }
        },

        clear() {
            this.$refs.form.reset()
        },

        store(item){
            this.$store.dispatch('storeRentalMonthlyFee', item)
                        .then(response => {
                            this.item = response.data 
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 

    }
}
</script>