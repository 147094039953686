export const TIPO_IMOVEL_PROPERTY_ITEMS = [
    { tipo: 'Apartamento', subtipos: ['Apartamento Padrão','Cobertura','Duplex','Triplex','Kitnet','Flat','Apart hotel','Loft','Pent house','Studio','Terreo','Garden'], finalidade: ['Residencial'], extra_area: [],  areas: { area: 'Área Útil', area_total: 'Área Total'} },
    { tipo: 'Área', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial', 'Industrial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Terreno', area_total: ''}},
    { tipo: 'Armazem', subtipos: [], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Casa', subtipos: ['Casa Padrão','Assobradada','Terrea','Sobrado'], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area:['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Chácara', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Chalé', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Conjunto', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [],  areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Fazenda', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Galpão', subtipos: [], finalidade: ['Comercial','Industrial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Hotel', subtipos: [], finalidade: ['Comercial'], extra_area: [],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Loja', subtipos: ['Sobre loja'], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Oportunidade Comerciais', subtipos: [], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Ponto Comercial', subtipos: [], finalidade: ['Comercial'], extra_area: [],  areas: { area: 'Área Const./Útil', area_total: 'Área Total/Terreno'}},
    { tipo: 'Pousada', subtipos: [], finalidade: ['Comercial'], extra_area: [],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Predio Comercial', subtipos: [], finalidade: ['Comercial'], extra_area: [],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Sala Comercial', subtipos: [], finalidade: ['Comercial'], extra_area: [],  areas: { area: 'Área Útil', area_total: 'Área Total'}},
    { tipo: 'Salão', subtipos: ['Salão Padrão','Galpão'], finalidade: ['Comercial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Const./Útil', area_total: 'Área Total / Terreno'}},
    { tipo: 'Sítio', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial'], extra_area: [],  areas: { area: 'Área Construída', area_total: 'Área Terreno'}},
    { tipo: 'Terreno', subtipos: [], finalidade: ['Residencial', 'Comercial','Residencial / Comercial', 'Industrial'], extra_area: ['Frente','Fundo','Lado Direito','Lado Esquerdo'],  areas: { area: 'Área Terreno', area_total: ''}}
]

export const REAJUSTE = [
    { value: 3, description: '3 meses' },
    { value: 6, description: '6 meses' },
    { value: 9, description: '9 meses' },
    { value: 12, description: '12 meses' },
]

export const INDICE_REAJUSTE = [
    { value: 'IGP-M/FGV', description: 'IGP-M/FGV' }
]

export const GARANTIAS = [
    { value: 'Depósito', description: 'Depósito' },
    { value: 'Fiador', description: 'Fiador' },
    { value: 'Seguro fiança', description: 'Seguro fiança' }
]

export const COBRANCA = [
    { value: 'Depósito', description: 'Depósito' },
    { value: 'Boleto', description: 'Boleto' }
]

export const TRANSFER_TO = ['Locador','Imobiliária','Outros']

export const CHARGES = ['Aluguel','Condomínio','IPTU','Seguro Fiança','Seguro Incêndio','Taxa','Água','Luz','Gás','Multa atraso','Outros']

export const DISCOUNTS = ['Abatimento no aluguel','Outros']

export const ADMS = ['Taxa adm','Comissão']

export const STATUS = ['Pendente','Em vigência','Concluído','Cancelado','Jurídico']

export const DATE_SEARCH = [
    { value: 'due_date', description: 'Data vencimento' },
    { value: 'payment_date', description: 'Data pagamento' },
    { value: 'transfer_owner_date', description: 'Data repasse'},
    { value: 'inicio_contrato', description: 'Data início contrato'},
    { value: 'fim_contrato', description: 'Data fim contrato'}
]

export const TYPES = [
    { value: 'charge', description: 'Encargo' },
    { value: 'discount', description: 'Desconto' },
    { value: 'adm', description: 'Adm' }
]
