<template>
    <div v-if="!object.id" class="mt-8">
        <main-component :object="item" @callLoadItem="loadItem"></main-component>
    </div>
    <div v-else>
        <v-tabs
            v-model="active_tab"
            show-arrows 
        >
            <v-tab href="#tab-1" class="caption"> Dados da locação</v-tab>
            <v-tab href="#tab-2" class="caption"> Locadores </v-tab>
            <v-tab href="#tab-3" class="caption"> Locatários </v-tab>
            <v-tab v-if="item.status == 'Pendente'" href="#tab-4" class="caption"> Gerar mensalidades e ativar locação </v-tab>
            <v-tab v-if="item.status != 'Pendente'" href="#tab-5" class="caption"> Mensalidades </v-tab>
            <v-tab v-if="item.status != 'Pendente'" href="#tab-6" class="caption"> Ações em lote </v-tab>
        </v-tabs>
    

        <v-tabs-items v-model="active_tab">
            <v-tab-item value="tab-1">
                <v-card flat class="pa-8">
                    <main-component :object="item" @callLoadItem="loadItem"></main-component>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat class="pa-8">
                    <rental-client-component :object="rental_owners" @callLoadItem="loadItem"></rental-client-component>
                </v-card>    
            </v-tab-item>                

            <v-tab-item value="tab-3">
                <v-card flat class="pa-8">
                    <rental-client-component :object="rental_tenants" @callLoadItem="loadItem"></rental-client-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-4" v-if="item.status == 'Pendente'">
                <v-card flat class="pa-8">
                    <store-rental-monthly-fee-component :object="item" @callLoadItem="loadItem"></store-rental-monthly-fee-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-5" v-if="item.status != 'Pendente'">
                <v-card flat class="pa-8">
                    <rental-monthly-fee-component :object="item" @callLoadItem="loadItem" ref="monthly_fee"></rental-monthly-fee-component>
                </v-card>    
            </v-tab-item>

            <v-tab-item value="tab-6" v-if="item.status != 'Pendente'">
                <v-card flat class="pa-8">
                    <mass-action-component :object="item" @callLoadItem="loadItem"></mass-action-component>
                </v-card>    
            </v-tab-item>

        </v-tabs-items>

    </div>    
</template>


<script>
import RentalClientComponent from '@/components/rental_clients/RentalClientComponent.vue'
import MainComponent from './MainComponent.vue'
import StoreRentalMonthlyFeeComponent from './StoreRentalMonthlyFeeComponent.vue'
import RentalMonthlyFeeComponent from './RentalMonthlyFeeComponent.vue'
import MassActionComponent from './MassActionComponent.vue'

export default {
    created() {
        this.setTab()
        this.setObject()
    },

    components: {
       MainComponent,
       RentalClientComponent,
       StoreRentalMonthlyFeeComponent,
       RentalMonthlyFeeComponent,
       MassActionComponent
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            if(this.item.id != this.object.id){
                this.setTab()
            }
            this.setObject()
        },
        
        item () {   
            this.setObject()
        },

        active_tab () {   
            this.loadPromisseRentalMonthlyFees()
        }
    },
    
    data () {
        return {
            active_tab: 'tab-1',
            item: {},

            rental_owners: {
                profile: "owner",
                rental_id: '',
                items: []
            },

            rental_tenants: {
                profile: "tenant",
                rental_id: '',
                items: []
            }
        }
    },

    methods: {
        changeStep(step){
            this.active_tab = step
        },

        loadItem(id){
            this.$emit('callLoadItem', id)
        },
        
        setTab(){
            this.active_tab = 'tab-1'
        },

        setObject(){
            this.item = this.object

            // rental owners
            this.rental_owners.rental_id = this.object.id
            this.rental_owners.items = this.object.json_rental_owners
            
            // rental tenants
            this.rental_tenants.rental_id = this.object.id
            this.rental_tenants.items = this.object.json_rental_tenants
        },

        loadPromisseRentalMonthlyFees(){
            if (this.active_tab && this.active_tab === 'tab-5') {
                this.$nextTick(() => {
                    if (this.$refs.monthly_fee) {
                        this.$refs.monthly_fee.loadPromisseRentalMonthlyFees();
                    }
                })
            }
        }
        
    },
}
</script>

<style scoped>

</style>