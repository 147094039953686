import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth/auth.js'
import preloader from './modules/preloader/preloader.js'
import uploads from './modules/uploads/uploads.js'
import changepassword from './modules/changepassword/changepassword.js'
import users from './modules/users/users.js'
import ceps from './modules/ceps/ceps.js'
import texts from './modules/texts/texts.js'
import dashboard from './modules/dashboard/dashboard.js'
import comments from './modules/comments/comments.js'
import closerequestpassword from './modules/closerequestpassword/closerequestpassword.js'
import download from './modules/download/download.js'
import clients from './modules/clients/clients.js'
import rentals from './modules/rentals/rentals.js'
import rental_clients from './modules/rental_clients/rental_clients.js'
import rental_monthly_fees from './modules/rental_monthly_fees/rental_monthly_fees.js'
import rental_monthly_fee_values from './modules/rental_monthly_fee_values/rental_monthly_fee_values.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        preloader,
        uploads,
        changepassword,
        users,
        ceps,
        texts,
        dashboard,
        comments,
        closerequestpassword,
        download,
        clients,
        rentals,
        rental_clients,
        rental_monthly_fees,
        rental_monthly_fee_values
    }
})

export default store
