<template>
    <div>
        <alert-component  :alert="alerts.steps"></alert-component> 
        <v-form ref="form" class="p-2">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="search_type"
                        :items="items.search_types"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Pesquisa por</span>
                        </template>
                    </v-select>
                </v-col>
            
                <v-col cols="12"  xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-show="search_type == 'E-mail'"
                        v-model="params.email"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">E-mail</span>
                        </template>
                    </v-text-field>

                    <v-text-field
                        v-show="search_type == 'Telefone'"
                        v-model="params.telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Telefone</span>
                        </template>
                    </v-text-field>

                    <v-text-field
                        v-show="search_type == 'Nome'"
                        v-model="params.nome"
                        label="Nome"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Nome</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn
                        color="primary"
                        @click.prevent="validate()"
                        :class="$css_button"
                    >
                        Pesquisar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form> 

        <span v-if="this.search && clients.length == 0" class="justify-center" >
            <alert-component  :alert="alerts.new_client"></alert-component>    
            <v-btn
                dark
                color="primary"
                :class="$css_button"
                @click="newClient"
            >
                Cadastrar cliente
            </v-btn>
        </span>

        <!-- List  -->  
        <v-row v-if="clients && clients.length > 0" class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">  
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="clients"
                        :items-per-page="10"
                        sort-by="nome"
                        class="elevation-1 table-striped"
                        :footer-props="{
                        'items-per-page-text':'Itens por página',   
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                    >
                        <template v-slot:[`item.nome`]="{ item }">
                            <span :class="$css_report_list">{{ item.nome }}</span>
                        </template>

                        <template v-slot:[`item.telefone`]="{ item }">
                            <span :class="$css_report_list" v-if="item.telefone">{{ item.telefone | VMask(maskPhone(item.telefone)) }}</span>
                            <span class="ma-2"></span>
                            <span :class="$css_report_list" v-if="item.telefone2">{{ item.telefone2 | VMask(maskPhone(item.telefone2)) }}</span>
                        </template>

                        <template v-slot:[`item.email`]="{ item }">
                            <span :class="$css_report_list">{{ item.email }}</span>
                        </template>

                        <template v-slot:[`item.obs`]="{ item }">
                            <span :class="$css_report_list">{{ item.obs }}</span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-card-actions>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            class="ma-2"
                                            color="pink"
                                            label
                                            text-color="white"
                                            v-bind="attrs" v-on="on"
                                            @click="addClient(item.id)"
                                        >
                                            <v-icon left>
                                                mdi-label
                                            </v-icon>
                                            Adicionar cliente
                                        </v-chip>
                                    </template>
                                    <span>Adicionar - {{ item.nome }}</span>
                                </v-tooltip>
                            </v-card-actions>     
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>    

    </div>
</template>

<script>
import AlertComponent from '../alert/AlertComponent.vue'

export default {
    name: 'FormSearchClientComponent',

    components: {
        AlertComponent
    },

    props: {
        object: {
            require: true,
        },
    },

    watch: {
        search_type () {   
            this.setSearch()
        }
    },
    
    data () {
        return {
            search: false,
            search_type: 'Telefone',

            alerts: {
                new_client: {
                    type: 'warning',
                    icon: 'mdi-alert-outline',
                    messages: ['O sistema não encontrou nenhum registro com a informação pesquisada. Para cadastrar um novo cliente clique em "Cadastrar cliente"']
                },
                steps: {
                    type: 'info',
                    icon: 'mdi-alert-outline',
                    messages: ['Para inserir um novo cliente, realize a pesquisa primeiro pois o sistema irá verificar se o cliente já se encontra cadastrado. Após a pesquisa você poderá inserir o cliente.']
                },
            },

            params: {
                paginate: 'false',
                order: 'clients.nome',    
                nome: '',
                telefone: '',
                email: ''            
            },

            items: {
                search_types: ['Telefone','E-mail','Nome']
            },

            rules: {
                required: value => !!value || 'Obrigatório.',
                email: value => /.+@.+/.test(value) || 'E-mail precisa ser válido'
            },

            headers: [
                { text: 'NOME', value: 'nome' },
                { text: 'TELEFONE', value: 'telefone' },
                { text: 'EMAIL', value: 'email' },
                { text: 'OBS', value: 'description' },
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            clients:[]
        }
    },

    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.loadClients()
            }
        },

        loadClients(){
            this.search = false
            this.$store.dispatch('loadClients', this.params) 
                        .then(response => {
                            this.clients = response.data
                            this.search = true
                        })
        },

        addClient(client_id){
            const item = {}
            item.client_id = client_id
            item.rental_id = this.object.rental_id
            item.profile = this.object.profile
            this.$store.dispatch('storeRentalClient', item)
                        .then(response => {
                            this.$snotify.success('Cadastro com sucesso!')
                            this.$emit('callLoadItem', this.object.rental_id)
                            this.clear()
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })

        },

        clear(){
            this.clients = []
            this.params.nome = ''
            this.params.email = ''
            this.params.telefone = ''
            this.search_type = 'Telefone',
            this.search = false
        },
        
        setSearch(){
            this.params.nome = ''
            this.params.email = ''
            this.params.telefone = ''
        },

        newClient(){
            this.$emit('callNewClient',this.params)
        },

    }

}        
</script>