<template>
    <div>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.tipo_imovel"
                        :items="items.tipo_imovel"
                        :rules="[rules.required]"
                        outlined
                        dense
                        item-text="tipo"
                        item-value="tipo"
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Tipo</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.cep"
                        @blur="searchCep(item.cep)"
                        :rules="[rules.required]"
                        v-mask="['#####-###']"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">CEP</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
                    <v-text-field
                        v-model="item.endereco"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Endereço</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="item.bairro"
                        label="Bairro"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Bairro</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                        v-model="item.cidade"
                        label="Cidade"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cidade</span>
                        </template> 
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.estado"
                        label="Estado"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Estado</span>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <v-text-field
                        v-model="item.numero"
                        label="Número"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Número</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.tipo_imovel == 'Apartamento'" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.apartamento"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Apartamento</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col  cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.complemento"
                        label="Complemento"
                        outlined
                        dense
                        required
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Complemento</span>
                        </template>
                    </v-text-field>
                </v-col>  
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.prazo"
                        label="Número"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Prazo cont. em meses</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.inicio_contrato"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="item.inicio_contrato"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Início contrato</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="inicio_contrato" @input="calendar.inicio_contrato = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-menu
                        v-model="calendar.fim_contrato"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                v-model="item.fim_contrato"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-on="on"
                                :class="$css_field"
                            >
                                <template v-slot:label>
                                    <span :class="$css_field">Fim contrato</span>
                                </template>
                            </v-text-field>
                        </template>

                        <v-date-picker locale="pt-br" v-model="fim_contrato" @input="calendar.fim_contrato = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.reajuste"
                        :items="items.reajuste"
                        :rules="[rules.required]"
                        item-text="description"
                        item-value="value"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Period. Reajuste</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.indice_reajuste"
                        :items="items.indice_reajuste"
                        :rules="[rules.required]"
                        item-text="value"
                        item-value="value"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Indice Reajuste</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.comissao"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Comissão %</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field
                        v-model="item.taxa_adm"
                        :rules="[rules.required]"
                        type="number"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Taxa adm %</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.garantia"
                        :items="items.garantia"
                        :rules="[rules.required]"
                        item-text="value"
                        item-value="value"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Garantia</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-select
                        v-model="item.cobranca"
                        :items="items.cobranca"
                        :rules="[rules.required]"
                        item-text="value"
                        item-value="value"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Cobrança</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field
                        v-model="item.pasta"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Pasta (opcional)</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col v-if="item.id && item.status != 'Pendente'" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select
                        v-model="item.status"
                        :items="items.status"
                        :rules="[rules.required]"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Status</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                        v-model="item.obs"
                        outlined
                        dense
                        :class="$css_field"
                    >
                        <template v-slot:label>
                            <span :class="$css_field">Obs</span>
                        </template>
                    </v-textarea>
                </v-col>    
            </v-row>

            <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="text-right">
                    <v-btn
                        dark
                        color="primary"
                        @click="validate"
                        :class="$css_button"
                    >
                    Gravar
                    </v-btn>
                </v-col>    
            </v-row>

        </v-form>       
    </div>
</template>

<script>
import { TIPO_IMOVEL_PROPERTY_ITEMS, REAJUSTE, INDICE_REAJUSTE, GARANTIAS, COBRANCA, STATUS } from '../config_rentals/config_rentals.js'

export default {
    components: {
    },

    created() {
        this.setObject()
    },

    props: {
        object: {
            require: false,
        },
    }, 

    watch: {
        object () {   
            this.setObject()
        },

        inicio_contrato () {
            this.item.inicio_contrato = this.formatDate(this.inicio_contrato)
        },

        fim_contrato () {
            this.item.fim_contrato = this.formatDate(this.fim_contrato)
        },
    },

    data () {
        return {
            item: {
                endereco: '',
                bairro: '',
                cidade: '',
                estado: ''
            },

            items: {
                tipo_imovel: TIPO_IMOVEL_PROPERTY_ITEMS,
                reajuste: REAJUSTE,
                indice_reajuste: INDICE_REAJUSTE,
                garantia: GARANTIAS,
                cobranca: COBRANCA,
                status: STATUS
            },
            
            rules: {
                required: value => !!value || 'Obrigatorio',
            },

            inicio_contrato: '',
            fim_contrato: '',
            calendar: {
                inicio_contrato: '',
                fim_contrato: ''
            }
        }
    },

    methods: {
        setAddressNull(){
            this.item.endereco = '...'
            this.item.bairro = '...'
            this.item.cidade = '...'
            this.item.estado = '..'
        },

        searchCep(value){
            this.setAddressNull()
            if(!value) return ''
            this.$store.dispatch('loadCep', value) 
                        .then(response => {
                            this.setAddress(response)
                        })
        },

        setAddress(address){
            this.item.endereco = address.logradouro
            this.item.bairro = address.bairro
            this.item.cidade = address.localidade
            this.item.estado = address.uf
        },

        setObject(){
            if(this.object && this.object.id != undefined){
                this.item = this.object
            }
        },

        validate () {
            if (this.$refs.form.validate()) {
                this.storeUpdate(this.item)
            }
        },

        storeUpdate(item){
            const action = item.id ? 'updateRental' : 'storeRental' 
            this.$delete(item, 'json_rental_owners')
            this.$delete(item, 'json_rental_tenants')
            this.$store.dispatch(action, item)
                        .then(response => {
                            this.item = response.data 
                            this.$emit('callLoadItem',this.item.id)
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            console.log(error)
                            this.$snotify.error('Erro no cadastro')
                        })
                   
        }, 
    },
}
</script>

<style scoped>

</style>